import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Card, Button, Row, Col} from "react-bootstrap"
import Img from "gatsby-image"

const Lakes = () => {
  const data = useStaticQuery(graphql`
    query LakesComponentQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___area], order: DESC }, filter: {frontmatter: {type: {eq: "lakes"}}}) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              imageAttribution
              area
              details {
                name
                value
              }
              link
              linkName
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const lakes = data.allMarkdownRemark.edges
  return (
    <div>
      <Row>
        {lakes.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Col md={4} key={title}>
              <Card className="hoverCard" bg="secondary" style={{ color: 'var(--textNormal)', }}>
                <Link style={{ boxShadow: `none` }} to={"/lakes" + node.fields.slug}>
                {
                  node.frontmatter.image
                  ?
                  <Img className="project" fluid={node.frontmatter.image.childImageSharp.fluid} alt={node.frontmatter.title} />
                  :
                  ''
                }
                </Link>
                  <Card.Body>
                    <Link style={{ boxShadow: `none`, color: 'var(--textLink)', }} to={"/lakes" + node.fields.slug}>
                      <Card.Title>{title}</Card.Title>
                    </Link>
                    <hr />
                    {
                      node.frontmatter.details ?
                        node.frontmatter.details.map((detail) => {
                          return(
                            <div className="detail" key={detail.name}>
                              <p><b>{detail.name}:</b> {detail.value}</p>
                            </div>
                          )
                        }
                        )
                        :
                        ''
                      }
                      <hr />
                      <Card.Text>
                        {node.frontmatter.description}
                      </Card.Text>
                      <Link to={"/lakes" + node.fields.slug}>
                        <Button
                            variant="primary"
                            key={node.frontmatter.slug}
                            style={{ marginLeft: 10 }}
                          >
                          View
                        </Button>
                        </Link>
                        <Button
                          variant="secondary"
                          key={node.frontmatter.link}
                          href={node.frontmatter.link}
                          style={{ marginLeft: 10 }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {node.frontmatter.linkName}
                        </Button>
                  </Card.Body>
              </Card>
            </Col>
          )
        })}
    </Row>
    </div>
  )
}

export default Lakes
